import {Component, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {SwitcherComponent} from "../elements/switcher/switcher.component";
import { SocialMediaComponent } from "../elements/social-media/social-media.component";
import { BookingFormComponent } from "../elements/booking-form/booking-form.component";
import {ModalMenuComponent} from "../elements/modal-menu/modal-menu.component";
import {LanguageService} from "../../services/language.service";
import {TextElementComponent} from "../elements/text-element/text-element.component";

@Component({
  selector: 'app-footer',
  standalone: true,
    imports: [CommonModule, SwitcherComponent, SocialMediaComponent, BookingFormComponent, ModalMenuComponent, TextElementComponent],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent implements OnInit {
  constructor(public languageService: LanguageService) {}
  ngOnInit(){
    this.languageService.getBrowserLocal();
  }
}
